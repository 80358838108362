import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
  }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
      <Layout>
        <div id="indexGrid">
          <SEO title="Home" />
          <div className="blog-post-container">
            <div className="blog-post">
              <h1>{frontmatter.title}</h1>
              <h4>{frontmatter.date}</h4>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
          <div style={{gridColumn: 2}}>
            {/* <div style={{ maxWidth: `400px`, margin: `0 auto`, zIndex: 1000 }}>
              <Link  to="/">
                <Image />
              </Link>
            </div> */}
            {/* <div className="navigation" style={{ margin: `0 auto`, zIndex: 20000}}>
              <Sidebar></Sidebar>
            </div> */}
          </div>
        </div>
      </Layout>
    )
  }
  export const pageQuery = graphql`
    query($slug: String!) {
      markdownRemark(frontmatter: { slug: { eq: $slug } }) {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          slug
          title
        }
      }
    }
  `